<template lang="pug">
  .calendar-header
    #holiday-tooltip
    .indent
      .calendar-explanation(
        v-if="isCalendarExplanationShown"
        id="calendar-explanation"
      )
        u.title {{ $t("price_calendar.calendar_explanation.title") }}
        FaIcon.icon(icon="question-circle")
      BPopover(
        target="calendar-explanation"
        triggers="hover"
        placement="bottom"
        custom-class="wrapper"
      )
        CalendarExplanationPopover
    ScrollSync.calendar-header-months(horizontal)
      .months
        .month(v-for="(days, month) in groupedDates")
          .month-title-wrapper
            .month-title {{ monthTitleByLength(month, days, 1) }}
          .days
            .day-wrapper(
              v-for="{ day, weekday, fnsDate, date } in days"
              :class="{ weekend: isWeekend(date) || japaneseHolidayName(date), sunday: isSunday(date) || japaneseHolidayName(date) }"
            )
              .day(
                :title="japaneseHolidayName(date)"
                v-b-tooltip.hover="{ container: '#holiday-tooltip' }"
              )
                .value(:class="{ holiday: japaneseHolidayName(date) }") {{ day }}
                AppTooltip(
                  v-if="isAutoSetPrices && invalidDates.includes(fnsDate)"
                  icon="exclamation-triangle"
                  placement="right"
                  type="warning"
                  :title="$t('price_calendar.calendar.calculation_impossible_tooltip')"
                )
              .weekday {{ $t(`datepicker.weekdays_min.${weekday.toLowerCase()}`) }}
</template>

<script>
  // misc
  import { format as dateFormat, isWeekend, isSunday } from "date-fns"
  import { map, groupBy } from "lodash-es"
  import { monthTitleByLength, japaneseHolidayName } from "@/helpers/calendar-header"
  import { isRateCheckModeMode, isAutoSetPrices } from "@/helpers/price-calendar"
  import { DATE_FNS_DATE_FORMAT } from "@/config/constants"

  // components
  import { BPopover } from "bootstrap-vue"

  export default {
    components: {
      CalendarExplanationPopover: () => import("./CalendarExplanationPopover"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      BPopover
    },

    props: {
      dateRange: Array,
      invalidDates: {
        type: Array,
        default: () => new Array()
      }
    },

    computed: {
      isAutoSetPrices,
      isCalendarExplanationShown() {
        return !isRateCheckModeMode()
      },

      formattedDates({ dateRange }) {
        return map(dateRange, date => {
          return {
            day: dateFormat(date, "d"),
            weekday: dateFormat(date, "eeee"),
            fnsDate: dateFormat(date, DATE_FNS_DATE_FORMAT),
            date
          }
        })
      },

      groupedDates() {
        return groupBy(this.formattedDates, ({ date }) => dateFormat(date, "yyyy-MM"))
      }
    },

    methods: {
      dateFormat,
      isWeekend,
      isSunday,
      monthTitleByLength,
      japaneseHolidayName
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .wrapper
    max-width: unset
    box-shadow: 0px 6px 12px transparentize($default-gray, 0.7)
    border: unset
    border-radius: 4px

    ::v-deep
      .popover-body
        padding: unset

      .arrow
        &::before
          border-bottom-color: white

  .calendar-header
    display: flex

    #holiday-tooltip
      +tooltip($default-red)

    .indent
      border-right: 1px solid $default-purple-light-line
      box-sizing: content-box
      min-width: 315px
      width: 315px
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center

      .calendar-explanation
        font-size: 11px
        color: $default-gray
        display: flex
        align-items: center
        gap: 5px
        user-select: none
        width: fit-content

    .calendar-header-months
      border-right: 1px solid $default-purple-light-line
      align-items: center
      display: flex
      overflow-x: auto
      height: auto
      background-color: $th-background-color
      font-size: 0.8rem

      .months
        display: flex

        .month

          .month-title-wrapper
            font-size: 1rem
            padding: 15px 0
            white-space: nowrap

            .month-title
              padding: 0 10px
              color: $default-purple
              font-weight: 900
              width: fit-content
              position: sticky
              left: 0

        .days
          display: flex

          .day-wrapper
            color: $default-purple
            text-align: center
            width: 90px

            .weekday,
            .day
              align-items: center
              display: flex
              cursor: default
              justify-content: center
              height: 30px

            .day
              border-right: 1px solid transparentize($default-purple, 0.8)

              .value
                &.holiday
                  align-items: center
                  border: 1px solid $default-red
                  border-radius: 50%
                  display: flex
                  justify-content: center
                  height: 24px
                  width: 24px

            .weekday
              background-color: $default-white

            &:last-child
              .day
                border-right: 1px solid transparentize($default-purple, 0.8)

            &.holiday
              white-space: nowrap
              overflow: hidden
              text-overflow: ellipsis

            &.weekend
              font-weight: 900

            &.sunday
              color: $default-red
</style>
